import { Player } from '@lottiefiles/react-lottie-player';

const SetupResourcesPlayer = () => {
    return (
        <Player
            src={'https://res.cloudinary.com/honeybook/raw/upload/v1673793171/finance/lotties/setup_resources.json'}
            style={{ height: '545px', width: '400px' }}
            autoplay
            loop={true}
        />
    );
};

export default SetupResourcesPlayer;
